import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap, faSchool } from '@fortawesome/free-solid-svg-icons';
import { Activity, ActivityType } from '../@types/activityEntry';

import DetailsModalTriggerButton from './DetailModalTriggerButton';
import { useTranslation } from 'react-i18next';

const TimeLineEntry = ({
  timeEntry,
  openDetailModal,
}: {
  timeEntry: Activity;
  openDetailModal: (data: Activity) => void;
}) => {
  function getIconByName(itemType: ActivityType) {
    if (itemType === 'work') {
      return faBriefcase;
    } else if (itemType === 'graduated') {
      return faGraduationCap;
    } else {
      return faSchool;
    }
  }

  const { t } = useTranslation();
  return (
    <VerticalTimelineElement
      className={'vertical-timeline-element-' + timeEntry.type}
      date={t(timeEntry.key + timeEntry.period).toString()}
      icon={<FontAwesomeIcon icon={getIconByName(timeEntry.type)} size='lg' />}
    >
      <h4 className='vertical-timeline-element-title'>{t(timeEntry.key + timeEntry.title)}</h4>
      {timeEntry.schoolType ? (
        <h6 className='vertical-timeline-element-subtitle'>{t(timeEntry.key + timeEntry.schoolType)}</h6>
      ) : null}
      <h6 className='vertical-timeline-element-subtitle'>
        @ <a href={timeEntry.location.url}>{t(timeEntry.key + timeEntry.location.name)}</a>
      </h6>
      <h6 className='vertical-timeline-element-subtitle'>{t(timeEntry.key + timeEntry.location.city)}</h6>
      <p>
        <strong>{t(timeEntry.key + timeEntry.subtitle)}</strong>
      </p>
      {timeEntry.description ? <p>{t(timeEntry.key + timeEntry.description)}</p> : null}
      {timeEntry.details ? <DetailsModalTriggerButton openDetailModal={openDetailModal} data={timeEntry} /> : null}
    </VerticalTimelineElement>
  );
};

export default TimeLineEntry;
