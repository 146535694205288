import React, { useState, useRef, useEffect } from 'react';
import { Form, Col, Row, Container } from 'react-bootstrap';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import './Projects.scss';

import projects from './projects.json';
import filters from './filters.json';

import SectionHeader from '../components/SectionHeader';
import ProjectPreviewElement from './ProjectPreviewElement';
import ProjectModal from './ProjectModal';
import { useTranslation } from 'react-i18next';
import { Project } from '../@types/project';

export default function Projects() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<Project | null>(null);
  const [selectedFilter, setSelectedFilter] = useState('*');

  const isotope = useRef<Isotope | null>();

  useEffect(() => {
    isotope.current = new Isotope('#filter-container', {
      itemSelector: '.grid-item',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer',
      },
    });
    imagesLoaded('#filter-container', function () {
      isotope.current?.layout();
    });

    return () => isotope.current?.destroy();
  }, []);

  useEffect(() => {
    if (selectedFilter === '*') isotope.current?.arrange({ filter: `*` });
    else isotope.current?.arrange({ filter: selectedFilter });
  }, [selectedFilter]);

  function openModelWithItem(item: Project) {
    setOpenModal(true);
    setModalData(item);
  }

  function closeModel() {
    setOpenModal(false);
  }

  return (
    <>
      <section className='projects' id='projects'>
        <Container className='container'>
          <SectionHeader title={t('project.title')} />
          <Form className='categories-grid'>
            <Form.Group as={Row} controlId='categoryFilterSelect'>
              <Form.Label column sm={2}>
                Filter
              </Form.Label>
              <Col sm={10}>
                <Form.Control as='select' onChange={(e) => setSelectedFilter(e.target.value)} value={selectedFilter}>
                  {filters.map((filter, i) => (
                    <option key={i} disabled={filter.disabled} value={filter.filter}>
                      {(filter.isSub ? '- ' : '') + t('project.filter.' + filter.name)}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
          <div className='grid' id='filter-container'>
            <div className='grid-sizer col-xl-4 col-md-6 col-xs-12' />
            {projects.map((item, i) => (
              <ProjectPreviewElement key={i} item={item} onClick={() => openModelWithItem(item)} />
            ))}
          </div>
        </Container>
        <ProjectModal modalData={modalData as Project} openModal={openModal} onClose={() => closeModel()} />
      </section>
    </>
  );
}
