import React, { useState } from 'react';

import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import './Background.scss';
import backgrounds from './timeEntries.json';

import SectionHeader from '../components/SectionHeader';

import BackgroundDetailModal from './BackgroundDetailModal';
import { useTranslation } from 'react-i18next';
import { Activity } from '../@types/activityEntry';
import TimeLineEntry from './TimeLineEntry';

function Background() {
  const [isDetailModalOpen, setOpenDetailModal] = useState(false);
  const [detailModalData, setDetailModalData] = useState<Activity | null>(null);
  const { t } = useTranslation();

  function closeDetailModal() {
    setOpenDetailModal(false);
  }

  function openDetailModal(details: Activity) {
    setOpenDetailModal(true);
    setDetailModalData(details);
  }

  return (
    <section id='background'>
      <div className='container'>
        <SectionHeader title={t('background.title')} />
        {
          <VerticalTimeline>
            {backgrounds.map((data, i) => (
              <TimeLineEntry key={i} timeEntry={data as Activity} openDetailModal={openDetailModal} />
            ))}
          </VerticalTimeline>
        }
      </div>
      <BackgroundDetailModal data={detailModalData} openModal={isDetailModalOpen} onClose={() => closeDetailModal()} />
    </section>
  );
}

export default Background;
