import React from 'react';
import './footer.scss';

function Footer() {
  return (
    <footer className='text-center'>
      <div className='footer-below'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>Copyright &copy; enchan {new Date().getFullYear()}</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
