import React from 'react';
import { Link } from 'react-scroll';
import { Navbar, Nav } from 'react-bootstrap';
import './header.scss';
import { useTranslation } from 'react-i18next';
import { Section } from '../@types/section';
import {
  faBitbucket,
  faFacebookSquare,
  faGithubSquare,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = ({ sections }: { sections: Section[] }) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header>
      <Navbar fixed='top' expand='lg' bg='dark' variant='dark' className='navbar-custom flex-wrap flex-lg-nowrap'>
        <Navbar.Brand href='#home'>
          <img
            src={process.env.PUBLIC_URL + '/favicon/favicon-brand.png'}
            width='30'
            height='30'
            className='d-inline-block align'
            alt='enchan-Website logo'
          />
          enchan
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='enchanNav' />
        <Navbar.Collapse id='enchanNav'>
          <ul className='navbar-nav flex-row flex-wrap pt-2 py-md-0'>
            {sections.map((section, i) => (
              <li key={i} className='nav-item col-6 col-lg-auto '>
                <Link
                  activeClass='active'
                  spy={true}
                  key={section.name}
                  to={section.name}
                  className='nav-link'
                  smooth={true}
                  offset={-100}
                >
                  {t('menu.' + section.name)}
                </Link>
              </li>
            ))}
          </ul>
          <hr className='d-lg-none text-white-50' />
          <ul className='navbar-nav flex-row flex-wrap ms-lg-auto socials'>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link href='https://www.facebook.com/enrico.gallus'>
                <FontAwesomeIcon icon={faFacebookSquare} />
                <small className='d-lg-none ms-2'>Facebook</small>
              </Nav.Link>
            </li>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link href='https://twitter.com/dillinijah'>
                <FontAwesomeIcon icon={faTwitterSquare} />
                <small className='d-lg-none ms-2'>Twitter</small>
              </Nav.Link>
            </li>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link href='https://www.instagram.com/dillinijah'>
                <FontAwesomeIcon icon={faInstagramSquare} />
                <small className='d-lg-none ms-2'>Instagram</small>
              </Nav.Link>
            </li>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link href='https://bitbucket.org/enricogallus/'>
                <FontAwesomeIcon icon={faBitbucket} />
                <small className='d-lg-none ms-2'>Bitbucket</small>
              </Nav.Link>
            </li>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link href='https://github.com/EnricoGallus'>
                <FontAwesomeIcon icon={faGithubSquare} />
                <small className='d-lg-none ms-2'>GitHub</small>
              </Nav.Link>
            </li>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link href='https://www.linkedin.com/in/enrico-gallus-61a88211b/'>
                <FontAwesomeIcon icon={faLinkedin} />
                <small className='d-lg-none ms-2'>LinkedIn</small>
              </Nav.Link>
            </li>
          </ul>
          <hr className='d-lg-none text-white-50' />
          <ul className='navbar-nav flex-row flex-wrap ms-lg-auto languageSelector text-center'>
            <li className='nav-item ml-auto col-lg-auto'>
              <div className='vr d-none d-lg-flex h-100 mx-3 text-white'></div>
            </li>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link className={i18n.language === 'en' ? 'active' : ''} onClick={() => changeLanguage('en')}>
                EN
              </Nav.Link>
            </li>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link className={i18n.language === 'de' ? 'active' : ''} onClick={() => changeLanguage('de')}>
                DE
              </Nav.Link>
            </li>
            <li className='nav-item col-4 col-lg-auto'>
              <Nav.Link className={i18n.language === 'ja' ? 'active' : ''} onClick={() => changeLanguage('ja')}>
                日本語
              </Nav.Link>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
