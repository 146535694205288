import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Project } from '../@types/project';

const ProjectModal = ({
  modalData,
  openModal,
  onClose,
}: {
  modalData: Project;
  openModal: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const data = modalData;
  if (!openModal || data === undefined || data.disabled) {
    return null;
  } else
    return (
      <Modal size='lg' show={openModal} onHide={onClose} id='projectModal'>
        <Card>
          <Card.Header as='h4'>{data.name}</Card.Header>
          <Card.Body>
            <Card.Title>{t(data.key + data.preview.description)}</Card.Title>
            <Card.Subtitle className='mb-2 text-muted'>{t(data.key + data.subtitle)}</Card.Subtitle>
            <Card.Text className='py-3'>{t(data.key + data.details.content)}</Card.Text>
            <ListGroup className='pb-2'>
              {data.details.programmingLanguages.length > 0 ? (
                <ListGroup.Item>{data.details.programmingLanguages.join(' | ')}</ListGroup.Item>
              ) : null}
              {data.details.technologies.length > 0 ? (
                <ListGroup.Item>{data.details.technologies.join(' | ')}</ListGroup.Item>
              ) : null}
              {data.details.tools.length > 0 ? <ListGroup.Item>{data.details.tools.join(' | ')}</ListGroup.Item> : null}
            </ListGroup>
            {data.details.references.map((item, i) => (
              <Card.Link key={i} href={item.url}>
                {item.text}
              </Card.Link>
            ))}
          </Card.Body>
          <Card.Footer className='text-center'>
            <Button variant='primary' onClick={onClose}>
              {t('Close')}
            </Button>
          </Card.Footer>
        </Card>
      </Modal>
    );
};

export default ProjectModal;
