import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';
import './index.scss';
import './custom.scss';
import * as Sentry from '@sentry/browser';
import App from './app';
import reportWebVitals from './reportWebVitals';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

Sentry.init({ dsn: 'https://d59b1252437949358274ea439bfce5ed@sentry.io/5171403' });

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: './locales/{{lng}}/translation.json',
    },
    load: 'languageOnly',
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
  })
  .then(() => {
    ReactGA.initialize('UA-159920869-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const root = createRoot(document.getElementById('root') as HTMLElement);
    root.render(
      <React.StrictMode>
        <GoogleReCaptchaProvider reCaptchaKey='6LdTDuMUAAAAAC0OM5Iq8eeQckwwtS4hEcsxIC49'>
          <App />
        </GoogleReCaptchaProvider>
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
