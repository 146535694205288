import React from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../@types/project';

const ProjectPreviewElement = ({ item, onClick }: { item: Project; onClick: () => void }) => {
  const { t } = useTranslation();
  const previewData = item.preview;
  const key = item.key;
  return (
    <div className={'grid-item col-xl-4 col-md-6 col-xs-12 ' + previewData.filterAttributes.join(' ')}>
      <div className='grid-item-content '>
        <div className='modal-trigger' data-toggle='modal' data-target='#portfolioDetailModal' onClick={onClick}>
          <img
            src={require(`../images/portfolio/${previewData.imageUrl}`)}
            alt={t(key + previewData.imageAltText).toString()}
            className='img-fluid img-responsive'
          />
          <div className='grid-item-hover'>
            <div className='portfolio-border clearfix'>
              <div className='grid-item-info'>
                <span>{t(key + previewData.title)}</span>
                <em>{t(key + previewData.description)}</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPreviewElement;
