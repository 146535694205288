import React, { useEffect } from 'react';
import Header from './navigation/header';
import Footer from './navigation/footer';
import Home from './sections/home';
import About from './sections/about';
import Background from './background/Background';
import Projects from './projects/Projects';
import Contact from './sections/contact';
import $ from 'jquery';
import { Section } from './@types/section';

function App() {
  const sections: Section[] = [{ name: 'about' }, { name: 'background' }, { name: 'projects' }, { name: 'contact' }];

  useEffect(() => {
    $('#status').fadeOut(); // will first fade out the loading animation
    $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
    $('body').delay(350).css({
      overflow: 'visible',
    });
  });

  return (
    <div>
      <Header sections={sections} />
      <Home />
      <About />
      <Background />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
