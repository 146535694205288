import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Activity } from '../@types/activityEntry';

const BackgroundDetailModal = ({
  openModal,
  data,
  onClose,
}: {
  openModal: boolean;
  data: Activity | null;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  if (!openModal || data == null) {
    return null;
  }

  return (
    <Modal animation={true} show={openModal} onHide={onClose} id='background-detail-modal' centered>
      <Modal.Body>
        <Card>
          <Card.Header as='h4'>{t(data.key + data.title)}</Card.Header>
          <Card.Body>
            <Card.Title>@ {t(data.key + data.location.name)}</Card.Title>
            <Card.Subtitle className='mb-2 text-muted'>{t(data.key + data.location.city)}</Card.Subtitle>
            <ListGroup>
              <ListGroup.Item>{t(data.key + data.details?.content)}</ListGroup.Item>
              {(data.details?.programmingLanguages.length || 0) > 0 ? (
                <ListGroup.Item>{data.details?.programmingLanguages.join(' | ')}</ListGroup.Item>
              ) : null}
              {(data.details?.technologies.length || 0) > 0 ? (
                <ListGroup.Item>{data.details?.technologies.join(' | ')}</ListGroup.Item>
              ) : null}
              {(data.details?.tools.length || 0) > 0 ? (
                <ListGroup.Item>{data.details?.tools.join(' | ')}</ListGroup.Item>
              ) : null}
            </ListGroup>
          </Card.Body>
          <Card.Footer className='text-center'>
            <Button variant='secondary' onClick={onClose}>
              {t('background.close')}
            </Button>
          </Card.Footer>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default BackgroundDetailModal;
