import React from 'react';

const SectionHeader = ({ title }: { title: string }) => (
  <div className='row'>
    <div className='col-xl-12 text-center'>
      <h2>{title}</h2>
      <hr />
    </div>
  </div>
);

export default SectionHeader;
