import { Button } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Activity } from '../@types/activityEntry';

const DetailsModalTriggerButton = ({
  data,
  openDetailModal,
}: {
  data: Activity;
  openDetailModal: (data: Activity) => void;
}) => {
  const { t } = useTranslation();
  return (
    <p>
      <Button variant='outline-secondary' onClick={() => openDetailModal(data)}>
        {t('background.details')}
      </Button>
    </p>
  );
};

export default DetailsModalTriggerButton;
