import React from 'react';
import './about.scss';
import profilePic from '../images/profile.png';
import SectionHeader from '../components/SectionHeader';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <section id='about'>
      <div className='container'>
        <SectionHeader title={t('about.title')} />
        <div className='row'>
          <div className='col-lg-8'>
            <div className='jumbotron'>
              <p>{t('about.welcome')}</p>
              <p>{t('about.country')}</p>
              <p>{t('about.passion')}</p>
              <p>{t('about.hobbies')}</p>
              <p>{t('about.volunteering')}</p>
              <p>{t('about.japanese')}</p>
            </div>
          </div>
          <div className='col-lg-4 about-img-div'>
            <img src={profilePic} className='img-responsive pull-right' alt='profile' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
