import React, { useState } from 'react';
import './contact.scss';
import { FieldValues, useForm } from 'react-hook-form';
import axios from 'axios';
import $ from 'jquery';
import SectionHeader from '../components/SectionHeader';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [formDisabled, setFormDisabled] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const { t } = useTranslation();
  const onSubmit = (data: FieldValues) => {
    if (!isVerified) {
      $('#contact').fadeTo('slow', 1, function () {
        $('#bot-error').fadeIn();
      });
      return;
    }

    setFormDisabled(true);
    const url = 'https://wpnvnr354g.execute-api.ap-northeast-1.amazonaws.com/production/rest/contact';
    axios
      .post(url, data)
      .then(() => {
        (document.getElementById('contact-form') as HTMLFormElement)?.reset();
        $('#success').fadeIn();
      })
      .catch(() => {
        $('#contact').fadeTo('slow', 1, function () {
          $('#error').fadeIn();
        });
      })
      .finally(() => {
        setFormDisabled(false);
      });
  };

  const verifiedCallback = (token: string) => {
    if (token) {
      setVerified(true);
    }
  };

  return (
    <section id='contact'>
      <div className='container'>
        <SectionHeader title={t('contact.title')} />
        <div className='row'>
          <div className='col-lg-8 offset-lg-2'>
            <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate className='needs-validation'>
              <input
                type='text'
                id='name'
                className={'form-control ' + (errors.name ? 'is-invalid' : '')}
                disabled={formDisabled}
                placeholder={t('contact.name.placeholder').toString()}
                {...register('name', {
                  required: true,
                  minLength: 4,
                })}
              />
              {errors.name && errors.name.type === 'required' && (
                <div className='invalid-feedback'>{t('contact.name.required')}</div>
              )}
              {errors.name && errors.name.type === 'minLength' && (
                <div className='invalid-feedback'>{t('contact.name.minLength')}</div>
              )}
              <input
                type='email'
                id='email'
                className={'form-control ' + (errors.email ? 'is-invalid' : '')}
                disabled={formDisabled}
                placeholder={t('contact.email.placeholder').toString()}
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
              />
              {errors.email && errors.email.type === 'required' && (
                <div className='invalid-feedback'>{t('contact.email.required')}</div>
              )}
              {errors.email && errors.email.type === 'pattern' && (
                <div className='invalid-feedback'>{t('contact.email.pattern')}</div>
              )}
              <textarea
                id='message'
                className={'form-control ' + (errors.message ? 'is-invalid' : '')}
                disabled={formDisabled}
                placeholder={t('contact.message.placeholder').toString()}
                {...register('message', { required: true })}
              />
              {errors.message && <div className='invalid-feedback'>{t('contact.message.required')}</div>}
              <button id='submit' type='submit' className='btn btn-primary btn-large' disabled={formDisabled}>
                {t('contact.send.name')}
              </button>
              <div id='success'>
                <p className='green textcenter'>{t('contact.send.successful')}</p>
              </div>
              <div id='error'>
                <p>{t('contact.send.error')}</p>
              </div>
              <div id='bot-error'>
                <p>{t('contact.send.botError')}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <GoogleReCaptcha onVerify={verifiedCallback} />
    </section>
  );
}
