import React from 'react';
import './home.scss';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();
  return (
    <section id='home'>
      <div className='container'>
        <div className='jumbotron'>
          <h1 className='display-4 name'>{t('header.name')}</h1>
          <hr className='my-3' />
          <p className='lead'>{t('header.skills.1')}</p>
          <p className='lead'>{t('header.skills.2')}</p>
          <p className='lead'>{t('header.skills.3')}</p>
        </div>
      </div>
    </section>
  );
}

export default Home;
